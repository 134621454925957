


























































import Breadcrumb from '@/components/Breadcrumb.vue';
import BoardArticleList from '@/components/BoardArticleList.vue';
import Component, {mixins} from 'vue-class-component';
import GeneralUtil from '@/util/GeneralUtil';
import {BOARD_ID} from '../../util/BoardConst';

@Component({
  components: {
    GeneralUtil,
    Breadcrumb,
    BoardArticleList,
  },
})
export default class NoticePage extends GeneralUtil {
  notice_breadcrumb = {
    toplink: 'HOME',
    prevlink: '인재채용',
    present: '공지사항',
  };
  boardInfoId = BOARD_ID.BID_MAIN_NOICE;

  mounted() {
    this.$store.dispatch('Display/changeName', {name: '공지사항'});
  }
  data() {
    return {
      selectedCategory: '8',
      categories: [
        {value: '1', to: '/p/HrAbout', label: '채용안내'},
        {value: '2', to: '/p/HrNotice', label: '채용공고'},
        {value: '3', to: '/p/HrNoticeResult', label: '채용공고결과'},
        {value: '4', to: '/p/HrDbReg', label: '인재DB등록'},
        {value: '5', to: '/p/HrFaq', label: 'FAQ'},
        {value: '6', to: '/p/HrWant', label: '인재상'},
        {value: '7', to: '/p/HrWalfare', label: '복리후생'},
        {value: '8', to: '/p/Notice', label: '공지사항'},
        {value: '9', to: '/p/HrPolicy', label: '개인정보 처리방침'},
      ],
    };
  }
}
