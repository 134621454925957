

































import Breadcrumb from '@/components/Breadcrumb.vue';
import Component, {mixins} from 'vue-class-component';
import GeneralUtil from '@/util/GeneralUtil';
import {Prop, Ref} from 'vue-property-decorator';
import {BoardArticle, BoardArticleResponse, RepoPage, User} from '@/models';
import AXIOS from '../services/axios-wrapper';
import moment from 'moment';
import {BvTableCtxObject} from 'bootstrap-vue/src/components/table';
import boardService from '../services/board.service';

@Component({
  components: {
    GeneralUtil,
    Breadcrumb,
  },
})
export default class BoardArticleList extends GeneralUtil {
  @Prop(Number) readonly boardInfoId!: number;
  @Prop(String) readonly boardArticleReadLink!: string;

  boardFields = [
    {
      key: 'idx',
      label: 'No',
    },
    {
      key: 'title',
      label: '제목',
    },
    {
      key: 'startDate',
      label: '날짜',
      formatter: (value: string) => moment(value).format('YYYY-MM-DD'),
    },
  ];

  page: RepoPage = {
    number: 1,
    size: 10,
    totalElements: 10,
    totalPages: 1,
  };

  rowClicked(item: BoardArticle) {
    this.$router.push(`${this.boardArticleReadLink}${item.id}`);
  }

  async itemsProvider(ctx: BvTableCtxObject) {
    try {
      this.showLoadingModal();
      const response = await boardService.getArticleList(this.boardInfoId, this.page.number, this.page.size);
      this.page = response.page;
      // No field에는 실제 id가 아니라 글의 순서를 새로 매겨서 표시한다.
      let idx = this.page.totalElements - this.page.number * this.page.size;
      let idxList = response._embedded.boardArticles.map(x => {
        let newX = {...x, idx};
        idx -= 1;
        return newX;
      });

      this.page.number++; // API 기준은 0 base

      return idxList;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      this.hideLoadingModal();
    }
  }
}
